import { Outlet } from 'react-router';
import { styled } from "@mui/material/styles";
import { DashboardLayout } from '@toolpad/core/DashboardLayout';
import { PageContainer } from '@toolpad/core/PageContainer';
import { ToolbarAccountOverride } from '../components/SidebarFooterAccount/SidebarFooterAccount';
// import SidebarFooterAccount from '../components/SidebarFooterAccount/SidebarFooterAccount';
import Header from '../components/Header/Header';
import { useAppConfigState, useConfigState } from '../context/AppContext/AppContext';
import { Footer } from '../components/Footer/Footer';
import { myCorporatePrimaryColor } from '../constants/colors';
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { CssBaseline, StyledEngineProvider, ThemeProvider } from "@mui/material";
import { ToastContainer } from 'react-toastify';
import { videojetConnectTheme } from "../Theme";
import { useMemo, useEffect } from "react";
import { localeMap } from './LocalMap';
import { BannerProvider } from '../context/BannerContext/BannerContext';
import { TemplatesProvider } from '../context/TemplateContext';
import { useLayoutDispatch, toggleSidebar, openSidebar } from '../context/LayoutContext/LayoutContext';
import { ProgressIndicator } from '../components/ProgressIndicator/ProgressIndicator';

const FooterContainer = styled("div")(({ theme }) => ({
  flexShrink: 0,
  padding: theme.spacing(0, 1.5),
  width: '100%',
  position: 'fixed',
  bottom: 0,
  backgroundColor: theme.palette.background.paper,
}));

const dashboardLayoutStyles = {
  '& ul div ul .MuiCollapse-wrapperInner .MuiList-padding': {
    paddingLeft: '60px',
    '& > li': {
      padding: '0 !important',
    }
  },
  '& a': {
    height: '40px'
  },
  '& ul li > div': {
    height: '40px'
  },
  '& ul div ul': {
    '& a .MuiTypography-root': {
      fontSize: '14px !important',
      color: "rgba(255, 255, 255, 0.7) !important",
      fontWeight: '400 !important',
      '& .MuiListItemIcon-root': {
        color: "rgba(255, 255, 255, 0.7) !important",
        '& .MuiSvgIcon-root': {
          color: "rgba(255, 255, 255, 0.7) !important",
          '& path': {
            fill: "rgba(255, 255, 255, 0.7) !important",
          }
        }
      },
    },
    '& .Mui-selected .MuiTypography-root': {
      color: `${myCorporatePrimaryColor} !important`,
      '& .MuiListItemIcon-root': {
        color: `${myCorporatePrimaryColor} !important`,
        '& .MuiSvgIcon-root': {
          color: `${myCorporatePrimaryColor} !important`,
          '& path': {
            fill: "rgba(255, 255, 255, 0.7) !important",
          }
        }
      },
    },
  },
  '& a .MuiTypography-root': {
    fontSize: '15px',
    fontWeight: '500',
  },
  '& .MuiAppBar-root': {
    borderBottom: '#1c2220',
  },
  '& .MuiToolbar-root': {
    '& > .MuiStack-root': {
      '& > .MuiStack-root:first-of-type': {
        backgroundColor: '#1c2220',
        height: '70px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 67px 0 20px',
        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
        borderRadius: '4px',
        '& .MuiBox-root': {
          position: 'relative',
          zIndex: 10000,
          '& svg': { // Updated selector to target SVG elements directly
            // color: '#fff',
            '& path': {
              fill: '#fff', // Ensures the SVG path fill is white
            }
          }
        }

      },

      '& > .MuiStack-root:last-of-type': {
        display: 'none',
      },
    },
    '& > .MuiStack-root:last-of-type': {
      backgroundColor: myCorporatePrimaryColor,
      //width: '360px'
    },
    color: 'white',
    fontFamily: 'Arial, sans-serif',
    height: '70px',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '0px',
    paddingRight: '0px',
    position: 'relative',
    overflow: 'hidden',

    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '200%',
      height: '100%',
      background: `
            radial-gradient(circle at 0 100%, rgba(0, 0, 255, 0.2) 1px, transparent 1px),
            radial-gradient(circle at 10% 90%, rgba(0, 0, 255, 0.15) 1px, transparent 1px),
            radial-gradient(circle at 20% 80%, rgba(0, 0, 255, 0.1) 1px, transparent 1px)
          `,
      backgroundSize: '200px 100px',
      opacity: 0.2,
      pointerEvents: 'none',
    },
  },
  '& .MuiDrawer-root': {
    flexShrink: 0,
    '& .MuiDrawer-paper': {
      backgroundColor: '#2f2f2f',
      color: '#fff',
    },

  },
  'nav > ul > li:not(:first-of-type)': {
    borderTop: '1px solid rgba(216, 216, 216, 0.5)',
    borderWidth: 'thin',
  },
  '& .MuiListItem-root': {
    padding: '0px 10px', // Adjust this padding to make menu items narrower
    fontSize: '15px',
    '& .MuiSvgIcon-root': {
      color: 'rgba(255, 255, 255, 0.7)',
      '& path': {
        fill: 'rgba(255, 255, 255, 0.7)',
      }
    },
    '& .MuiListItemIcon-root': {
      color: 'rgba(255, 255, 255, 0.7)',
      '& .MuiSvgIcon-root': {
        color: 'rgba(255, 255, 255, 0.7)',
      }
    },
    '&:hover': {
      backgroundColor: '#2c2c2c',
    },
    '& .Mui-selected': {
      color: myCorporatePrimaryColor,
      backgroundColor: '#4a4a4a',
      '& .MuiTypography-root': {
        color: myCorporatePrimaryColor,
      },
      '& .MuiSvgIcon-root': {
        color: myCorporatePrimaryColor,
        '& path': {
          fill: myCorporatePrimaryColor,
        }
      },
    },
  },
};

export const ToolpadLayout = () => {
  const configState = useConfigState();
  const layoutDispatch = useLayoutDispatch();
  const { isLoading } = useAppConfigState();

  useEffect(() => {
    openSidebar(layoutDispatch);
  }, []);

  // Use a more robust approach to handle the sidebar toggle
  useEffect(() => {
    // Define the event listener function
    const handleClick = (event) => {
      const hamburgerButton = event.target.closest(
        '[aria-label="Collapse menu"] .MuiIconButton-root, [aria-label="Expand menu"] .MuiIconButton-root'
      );
      if (hamburgerButton) {
        toggleSidebar(layoutDispatch);
      }
    };

    // Add click listener to the entire document to catch all clicks
    document.addEventListener('click', handleClick, true); // Using capture phase

    // Clean up
    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  }, [layoutDispatch]);


  const { locale } = useConfigState();

  const gridTranslationsLocale = useMemo(() => {
    const localeItem = localeMap.find(x => x.id === locale);
    if (localeItem) {
      return localeItem.dateLocale ?? {};
    } else {
      console.log(`Locale ${locale} not found in localeMap`);
    }
    return {};
  }, [locale]);


  return (
    <TemplatesProvider>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={videojetConnectTheme(gridTranslationsLocale ?? {})}>
          <BannerProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <CssBaseline />
              <DashboardLayout
                slots={{
                  toolbarAccount: ToolbarAccountOverride,
                  sidebarFooter: ToolbarAccountOverride, //SidebarFooterAccount,
                  appTitle: Header,
                }}
                sx={dashboardLayoutStyles}
                sidebarExpandedWidth={350}
              >
                <PageContainer
                  maxWidth={false}
                  title="" // No title for the page container
                  breadcrumbs={[]} // No breadcrumbs for the page container 
                  sx={{ padding: '0px 5px !important' }}
                >
                  {isLoading && <ProgressIndicator />}
                  <Outlet />
                  {!configState.dashboardMode && (
                    <FooterContainer>
                      <Footer />
                    </FooterContainer>
                  )}
                </PageContainer>
              </DashboardLayout>
              <ToastContainer />
            </LocalizationProvider>
          </BannerProvider>
        </ThemeProvider>
      </StyledEngineProvider >
    </TemplatesProvider>
  );
}
