import {
  cloneElement,
  createContext,
  forwardRef,
  PropsWithChildren,
  ReactElement,
  SyntheticEvent,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { mySecondaryColor, myWhite } from "../../constants/colors";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { VariableSizeList } from "react-window";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { useVrsTranslationState } from "../../context/AppContext/AppContext";
const LISTBOX_PADDING = 8; // px
import { styled } from "@mui/material/styles";

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  '& .MuiAutocomplete-paper': {
    margin: 0,
    fontSize: theme.spacing(1.625)
  },
  '& .MuiAutocomplete-option': {
    minHeight: "auto",
    alignItems: "flex-start",
    padding: 8,
    '&[aria-selected="true"]': {
      backgroundColor: "transparent",
    }
  },
  "& .MuiAutocomplete-popperDisablePortal": {
    position: "relative",
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginTop: theme.spacing(0.2),
  marginBottom: theme.spacing(0.2),
  '& .MuiOutlinedInput-input': {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maskImage: 'linear-gradient(to right, black 75%, transparent 98%)',
  },
}));


const GroupTextContainer = styled("div")({
  fontSize: "13px",
  fontWeight: 600,
  padding: '0px 5px'
});

const OptionLabel = styled("span")(({ theme }) => ({
  backgroundColor: mySecondaryColor,
  color: myWhite,
  borderRadius: "6px",
  fontSize: theme.spacing(1.5),
  padding: theme.spacing(0.125, 1.125),
  marginRight: theme.spacing(1.125),
}));

const OptionPart1Container = styled("div")({
  display: "flex",
  alignItems: "center",
});

const IconContainer = styled("div")(({ theme }) => ({
  display: "flex",
  marginRight: theme.spacing(1.125),
  justifyContent: "center",
  alignItems: "center",
}));

interface SearchableSelectorProps {
  placeholder: string;
  onChange: (event) => void;
  onReset: (event) => void;
  value: string;
  options: Array<any>;
  fieldName: string;
  label?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  helperText?: string;
  errors?: any;
  disableDescription?: boolean;
  textSize?: "medium" | "small";
  rootClass?: any;
  disableClearable?: boolean;
  groupByFieldName?: string;
}

const ValueIcon = ({ options, value }: any) => {
  const selected = options ? options.find((el) => el.value === value) : null;
  return selected && selected.icon ? (
    <IconContainer>{selected.icon}</IconContainer>
  ) : null;
};

const OuterElementContext = createContext({});

function renderRow(props) {
  const { data, index, style } = props;
  return cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING,
    },
  });
}

// eslint-disable-next-line react/display-name
const OuterElementType = forwardRef((props, ref: any) => {
  const outerProps = useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
  const ref: any = useRef(null);
  useEffect(() => {
    if (ref.current !== null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

function textContent(elem: React.ReactElement | string): string {
  if (!elem) {
    return "";
  }
  if (typeof elem === "string") {
    return elem;
  }
  const children = elem.props && elem.props.children;
  if (children instanceof Array) {
    return children.map(textContent).join("");
  }
  return textContent(children);
}

// Adapter for react-window
const ListboxComponent: any = forwardRef(function ListboxComponent(
  props: any,
  ref
): PropsWithChildren<any> {
  const { children, ...other } = props;
  const itemData: ReactElement[] = [];
  (children as ReactElement[]).forEach(
    (item: ReactElement & { children?: ReactElement[] }) => {
      itemData.push(item);
      itemData.push(...(item.children || []));
    }
  );

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"), {
    noSsr: true,
  });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child: ReactElement) => {
    //eslint-disable-next-line no-prototype-builtins
    if (child.hasOwnProperty("group")) {
      return 48;
    }

    const content = textContent(child);

    if (content.length < 20) {
      return 36;
    }
    if (content.length < 30) {
      return 48;
    }
    if (content.length < 40) {
      return 64;
    }
    return 72;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref as any}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

function SearchableSelector({
  fieldName,
  label,
  onChange,
  onReset,
  value,
  isDisabled,
  isRequired,
  placeholder,
  errors,
  helperText,
  options,
  rootClass,
  disableDescription,
  textSize,
  disableClearable,
  groupByFieldName,
}: SearchableSelectorProps) {

  const { _T } = useVrsTranslationState();

  const removeAll = () => {
    onReset("");
  };

  const optionObj = useMemo(() => {
    if (!options) {
      return null;
    }
    const tempObj: any = {};
    options.forEach((el) => {
      tempObj[el.value] = el;
    });
    return tempObj;
  }, [options]);

  const groupBy: any = groupByFieldName
    ? { groupBy: (option) => option[groupByFieldName] }
    : {};

  const extra: any =
    options && options.length > 1000 ? { ListboxComponent } : {};
  return (
    <StyledAutocomplete
      {...extra}
      id={fieldName}
      value={value || ""}
      disableClearable={!!disableClearable}
      options={
        !options || !options.length
          ? [{ text: _T("No option"), value: "No option" }]
          : options
      }
      freeSolo
      /** Help show no option dropdown, freeSolo does not show it **/
      getOptionDisabled={(option: any) =>
        option.text && option.text.toLowerCase() === "no option"
      }
      isOptionEqualToValue={(option: any, value: any) =>
        option.value === value.value
      }
      classes={{
        root: rootClass,
      }}
      {...groupBy}
      fullWidth={true}
      disabled={isDisabled}
      renderGroup={(params) => (
        <Box key={params.key}>
          <GroupTextContainer>{params.group}</GroupTextContainer>
          {params.children}
        </Box>
      )}
      getOptionLabel={(option: any) => {
        // this is used to display options
        if (typeof option === "string") {
          const target = optionObj ? optionObj[option] : null;
          if (target) {
            return target.text;
          }

          return option;
        }
        // This is used in search
        return option.description || option.text;
      }}
      onChange={(_: SyntheticEvent, option: any, reason: string) => {
        if (reason === "clear") {
          removeAll();
        } else if (option) {
          onChange(option.value);
        }
      }}
      renderOption={(props: any, el: any) => {
        const { key, ...rest } = props;
        return (
          <Box component="li" {...rest} key={el.value} id={el.value}>
            <OptionPart1Container>
              {el.icon && <IconContainer>{el.icon}</IconContainer>}
              {el.label && <OptionLabel>{el.label}</OptionLabel>}
              {el.text && <div>{el.text}</div>}
            </OptionPart1Container>
            {!disableDescription && el.description && <div>{el.description}</div>}
          </Box>
        )
      }}
      renderInput={(params) => (
        <StyledTextField
          {...params}
          placeholder={placeholder}
          margin="normal"
          variant="outlined"
          fullWidth={true}
          label={label}
          required={isRequired}
          disabled={isDisabled}
          size={textSize || "medium"}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <ValueIcon options={options} value={value} />
              </InputAdornment>
            ),
            title: typeof value === 'string' ? value : '', // Add tooltip on hover
          }}
          error={errors && fieldName ? !!errors[fieldName] : false}
          helperText={
            helperText
              ? helperText
              : errors && fieldName && errors[fieldName]
                ? _T(errors[fieldName])
                : ""
          }
        />
      )}
    />
  );
}

SearchableSelector.displayName = "SearchableSelector";

export { SearchableSelector };
